import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "145",
  height: "48",
  fill: "none",
  viewBox: "0 0 145 48"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#4B286D",
      d: "M81.98 19.02v-2.404H73.41v12.368h8.572v-2.508h-5.098v-2.74h4.241V21.33h-4.241v-2.31h5.098M97.108 26.542H91.75v-9.926h-3.516v12.368h8.874v-2.442M125.414 21.33c-2.372-.534-3.2-.596-3.2-1.538 0-.876 1.174-1.15 1.851-1.15 1.098 0 2.385.256 3.696.932l.956-2.276c-1.344-.739-3.076-1.042-4.742-1.042-3.271 0-5.467 1.326-5.557 3.915-.095 2.357 1.822 3.242 3.588 3.607 1.699.35 3.422.653 3.46 1.661.033.932-.805 1.33-2.386 1.33-1.463 0-2.826-.469-4.217-1.05l-.81 2.47c1.595.682 3.219 1.055 4.895 1.055 2.324 0 4.018-.36 5.268-1.533.866-.805 1.15-1.775 1.15-2.703-.005-1.96-1.221-3.062-3.952-3.678M68.42 19.059v-2.443H57.633v2.443h3.644v9.925h3.498V19.06h3.645M109.098 16.616v7.858c0 .615 0 .785-.038 1.027-.109.747-.838 1.183-1.997 1.197-.857-.014-1.487-.265-1.78-.715-.147-.232-.218-.643-.218-1.306v-8.06h-3.645v8.524c0 1.183.157 1.713.692 2.386.88 1.102 2.593 1.68 4.96 1.68h.128c3.162 0 4.795-1.236 5.268-2.39.237-.56.256-.824.256-1.955v-8.246h-3.626M59.28 13.227l-20.438 4.07v1.425l.066-.018c13.504-3.28 20.372-5.254 20.429-5.269.075-.019.104-.08.094-.127-.014-.053-.066-.095-.151-.08"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#6C0",
      d: "M42.126 14.368c.318-.204.407-.076.313.151-.147.365-.838 2.58-5.988 6.802-1.344 1.098-2.295 1.718-4.231 2.864 3.166-4.435 7.79-8.444 9.906-9.817m.535-1.529c-.994.057-2.835 1.529-3.843 2.404-4.023 3.48-7.01 6.68-9.187 10.026-4.535 2.428-10.31 4.922-16.051 6.872l-.137.048-1.103 2.972.63-.246c3.649-1.392 9.74-4.004 15.264-6.906-.312.828-.468 1.59-.468 2.286 0 .521.085 1 .26 1.44.345.87 1.032 1.533 1.978 1.912 1.416.563 3.342.577 5.543-.09 5.619-1.704 12.288-6.935 13.74-7.976l.053-.038c.057-.043.061-.104.038-.147a.111.111 0 0 0-.152-.028l-.07.043c-1.558.932-9.126 5.424-14.05 6.745-2.503.672-4.415.303-5.135-.706-.25-.345-.369-.78-.369-1.287 0-.99.464-2.253 1.368-3.654a67.25 67.25 0 0 0 2.296-1.368c4.8-2.698 10.333-7.943 10.564-11.142v-.071c0-.275-.118-.559-.33-.772a1.067 1.067 0 0 0-.839-.317"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#4B286D",
      d: "m38.775 17.312-21.503 4.624-.833 2.235 22.403-5.449.033-.009v-1.42l-.1.019M131.373 16.616c.7 0 1.287.554 1.287 1.278a1.28 1.28 0 0 1-1.287 1.292 1.286 1.286 0 0 1-1.297-1.292c0-.724.592-1.278 1.297-1.278m0 2.38c.596 0 1.055-.473 1.055-1.102 0-.615-.459-1.089-1.055-1.089-.601 0-1.06.474-1.06 1.089-.005.63.454 1.103 1.06 1.103m-.502-1.85h.582c.355 0 .526.142.526.43 0 .27-.171.384-.398.408l.431.663h-.251l-.407-.644h-.246v.644h-.232v-1.5h-.005m.232.668h.246c.204 0 .398-.01.398-.247 0-.198-.171-.232-.332-.232h-.307v.479h-.005"
    }, null, -1)
  ])))
}
export default { render: render }